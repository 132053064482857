import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Learning Website Template | Learning Website Design | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/learning-web-app/"
    metaDescription=" Looking to design an e-learning website? Uizard's brand-new UX/UI template is the perfect place to start. Sign up for Uizard Pro today."
    description="
    h2:LearnU: Uizard's new e-learning website design template
    <br/>
    Looking to design an e-learning website? Uizard’s brand-new <a:https://uizard.io/templates/>UX/UI template</a>, LearnU, is the perfect place to start. LearnU comes with all the must-have screens for your learning web design including course progress screens, course search functionality mockup, and user profile elements. Move from idea to clickable prototype in no time at all with Uizard. 
    <br/>
    h3:Use AI to fast-track your e-learning web design
    <br/>
    If you want to create a web app mockup embodying Material Design, working in Uizard is the perfect choice for you. Uizard has the Material Design theme built-in, so you can use it when creating any mobile or web app mockup, <a:https://uizard.io/wireframing/>wireframe</a>, or prototype.
    <br/>
    h3:Endless web app creativity 
    <br/>
    Our photo web app template, PixLab, employs the tenets of Material Design giving your own design project a sound base in design best practices. Although PixLab is ready to use straight out of the box, Uizard's endless customization options mean you can really make your photo web app design your own.
    "
    pages={[
      "User statistics homepage including mockups of core content blocks for learning data",
      "'My Learning' screen with mockup of user performance data including course progress",
      "Course browser mockup demonstrating how users will find e-learning courses",
      "Course detail mockup screen outlining detailed course information"
    ]}
    projectCode="O4wRKled8RU1mmnLlGgb"
    img1={data.image1.childImageSharp}
    img1alt="Learning website cover page"
    img2={data.image2.childImageSharp}
    img2alt="User statistics homepage"
    img3={data.image3.childImageSharp}
    img3alt="Personal learning screen mockup"
    img4={data.image4.childImageSharp}
    img4alt="Course catalogue browsing screen"
    img5={data.image5.childImageSharp}
    img5alt="Learning website summary of all screens"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/learning-web-app/learning-app-web-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/learning-web-app/learning-app-web-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/learning-web-app/learning-app-web-learning.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/learning-web-app/learning-app-web-courses.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/learning-web-app/learning-app-web-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
